$navbar-bg: #191919;
$light-bg: #ebebeb;
$light-fg: #000;
$dark-bg: #121212;
$dark-fg: #fff;
$orange: #b35900;

body {
  margin: 0;
  padding: 0;
}

.dark {
  width: 100%;
  color: $dark-fg;
  background-color: $dark-bg;
}

.wrapper {
  min-height: 100vh;
  display: flex;
}

.dark2 {
  color: $dark-fg;
  background-color: $dark-bg;
}

.link {
  color: $orange;
}
.link:hover {
  color: $orange;
  font-weight: bold;
}
.link_dark {
  color: #ffa74f;
}
.link_dark:hover {
  color: #ffa74f;
  font-weight: bold;
}

.button-light {
  color: $light-fg;
}
.button-light:hover {
  color: $orange;
}
.button-dark {
  color: $dark-fg;
}
.button-dark:hover {
  color: $orange;
}

.test {
animation: fadeIn 5s;
-webkit-animation: fadeIn 5s;
-moz-animation: fadeIn 5s;
-o-animation: fadeIn 5s;
-ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
