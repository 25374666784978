.hov:hover {
  color: #b45900;
  background:
    linear-gradient(to right, #b35900 1px, transparent 4px) 5% 5%,
    linear-gradient(to right, #b35900 1px, transparent 4px) 5% 95%,
    linear-gradient(to left, #b35900 1px, transparent 4px) 95% 5%,
    linear-gradient(to left, #b35900 1px, transparent 4px) 95% 95%,
    linear-gradient(to bottom, #b35900 1px, transparent 4px) 5% 5%,
    linear-gradient(to bottom, #b35900 1px, transparent 4px) 95% 5%,
    linear-gradient(to top, #b35900 1px, transparent 4px) 5% 95%,
    linear-gradient(to top, #b35900 1px, transparent 4px) 95% 95%;

  background-repeat: no-repeat;
  background-size: 5px 5px;
}
